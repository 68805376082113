import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
} from 'react-router-dom';

import ComingSoonPage from '../core/Display/ComingSoonPage';

import Page404 from '../Page404';

import Home from '../components/Home/Home';
import Solutions from '../components/Solutions/Solutions';
import Footer from '../components/Footer/Footer';
import Profile from '../components/Profile/Profile';
import About from '../components/About/About';
import Header from '../components/Header/Header';
import Contact from '../components/Contact/Contact';
import LoginForm from '../components/LoginForm/LoginForm';
import PrivacyPolicy from '../components/About/PrivacyPolicy';
import TermsOfService from '../components/About/TermsOfService';
import DataSourceAdder from '../components/DataSourceAdder/DataSourceAdder';
import SignupForm from '../components/Signup/Signup';
import SignOut from '../components/SignOut/SignOut';
import ResetForm from '../components/Reset/ResetForm';
import ResetPassword from '../components/Reset/ResetPassword';
import Report, { RetrieveReportNavigate } from '../components/Report/Report';
import HMDAAggregate from '../components/HMDA/HMDAAggregate';
import HMDARaw from '../components/HMDA/HMDARaw';
import AuthRedirect from '../components/Common/AuthRedirect';
import Collection from '../components/Collection/Collection';
import SFAState from '../components/SFA/SFAState';
import SFAStates from '../components/SFA/SFAStates';
import SFACanada from '../components/SFA/SFACanada';
import SFATrends from '../components/SFA/SFATrends';
import SFAHMDA from '../components/SFA/SFAHMDA';
import DataSearch, { DataSearchSelection } from '../components/SFA/DataSearch';
import JournalHome from '../components/SFA/Journal';

import ObjectArray from '../components/Mockup/ObjectArray';

import {
  NULL_PATH, HOME_PATH, CONTACT_PATH,
  SIGNUP_PATH, RESET_PATH, RESET_PW_PATH, PROFILE_PATH, ABOUT_PATH, COMING_SOON_PATH,
  SOLUTIONS_PATH, DATASOURCEADDER_PATH, LOGIN_PATH, LOGOUT_PATH, NAVBAR_PAGES, REPORT_PATH,
  SAVED_REPORTS_PATH,
  HMDA_RAW_PATH,
  HMDA_AGGREGATE_PATH,
  SFA_STATE, SFA_HMDA, SFA_TRENDS, PRIVACY_POLICY_PATH, TOS_PATH, APP_STYLE,
  getPath,
} from '../constants';

function SiteWrapper() {
  return (
    <div>
      <Header navbarPages={NAVBAR_PAGES} />
      <div className="sizing">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

function DefaultRoutes() {
  const favicons = {
    default: '/favicon.ico',
    SFA: '/SFAfavicon.png',
  };

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }

    switch (APP_STYLE) {
      case 'sfa-variant':
        link.href = favicons.SFA;
        break;
      default:
        link.href = favicons.default;
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<SiteWrapper />}>
          <Route path={SFA_STATE} exact element={<SFAStates />} />
          <Route path={`${SFA_STATE}/:stateCode`} exact element={<SFAState />} />
          <Route path={`${SFA_HMDA}/*`} element={<SFAHMDA />} />
          <Route path={SFA_TRENDS} exact element={<SFATrends />} />
          <Route path={NULL_PATH} exact element={<Home />} />
          <Route path="/APIFrontEnd" exact element={<Home />} />
          <Route path={HOME_PATH} exact element={<Home />} />
          <Route
            path={DATASOURCEADDER_PATH}
            element={(
              <AuthRedirect>
                <DataSourceAdder />
              </AuthRedirect>
            )}
          />
          <Route path={CONTACT_PATH} exact element={<Contact />} />
          <Route path={SOLUTIONS_PATH} exact element={<Solutions />} />
          <Route path={SIGNUP_PATH} exact element={<SignupForm />} />
          <Route path={RESET_PATH} exact element={<ResetForm />} />
          <Route path={RESET_PW_PATH} element={<ResetPassword />} />
          <Route
            path={`${REPORT_PATH}/*`}
            exact
            element={<Report />}
          />
          <Route path={SAVED_REPORTS_PATH} element={<RetrieveReportNavigate />} />
          <Route
            path={PROFILE_PATH}
            exact
            element={(
              <AuthRedirect key={Date.now()}>
                <Profile />
              </AuthRedirect>
            )}
          />
          <Route path={`${HMDA_RAW_PATH}/*`} element={<HMDARaw />} />
          <Route path={`${HMDA_AGGREGATE_PATH}/*`} element={<HMDAAggregate />} />
          <Route path="/misc/:pageKey/*" element={<Collection />} />
          <Route path={LOGIN_PATH} exact element={<LoginForm />} />
          <Route path={LOGOUT_PATH} exact element={<SignOut />} />
          <Route path={ABOUT_PATH} exact element={<About />} />
          <Route path={PRIVACY_POLICY_PATH} exact element={<PrivacyPolicy />} />
          <Route path={TOS_PATH} exact element={<TermsOfService />} />
          <Route path={COMING_SOON_PATH} exact element={<ComingSoonPage />} />
          <Route
            path={getPath('SFA.GLOSSARY')}
            element={<DataSearch key="Glossary" pageName="Glossary" />}
          />
          <Route
            path={getPath('SFA.BIBLIOGRAPHY')}
            element={<DataSearch key="Bibliography" pageName="Bibliography" />}
          />
          <Route path={getPath('SFA.RMBS')} element={<DataSearchSelection />} />
          <Route path={getPath('SFA.JOURNALHOME')} element={<JournalHome />} />
          <Route path={getPath('SFA.SEC_CANADA')} element={<SFACanada />} />
          <Route path={`${getPath('SFA.SEC_CANADA')}/:province`} element={<ComingSoonPage />} />
          <Route
            path={getPath('SFA.GROSSISSUANCE')}
            element={<DataSearch pageName="Gross Issuance" />}
          />
          <Route
            path={getPath('SFA.SERVICERPERFORMANCE')}
            element={<DataSearch pageName="Servicer Performance" />}
          />
          <Route
            path={getPath('SFA.CRT')}
            element={<DataSearch pageName="CRT Indices" />}
          />
          <Route path="ObjectArray" element={<ObjectArray />} />
          <Route path="" element={<Home />} />
          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default DefaultRoutes;
