import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import './App.css';
import { APP_STYLE } from '../constants';

const DefaultTheme = lazy(() => import('./default/DefaultTheme'));
const SFATheme = lazy(() => import('./sfa-variant/SFATheme'));

function ThemeSelector({ children }) {
  let ChosenTheme = <DefaultTheme>{children}</DefaultTheme>;
  switch (APP_STYLE) {
    case 'sfa-variant':
      ChosenTheme = <SFATheme>{children}</SFATheme>;
      break;
    default:
  }
  return (
    <Suspense fallback={null}>
      {ChosenTheme}
    </Suspense>
  );
}

export default ThemeSelector;

ThemeSelector.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
