/*
 * For constants used throughout the project.
 */

const NONE = 'None';
const URL_PRE = process.env.REACT_APP_URL_PRE;

const MAP_ENDPOINT = 'Endpoints/map';

const NULL_USER = { NONE };
const USER_KEY = 'Auth-Key';
const NULL_PATH = '/';
const HOME_PATH = `${URL_PRE}/Home`;
const CONTACT_PATH = `${URL_PRE}/Contact`;
const DATASOURCEADDER_PATH = `${URL_PRE}/DataSourceAdder`;
const SIGNUP_PATH = `${URL_PRE}/Signup`;
const SOLUTIONS_PATH = `${URL_PRE}/solutions`;
const RESET_PATH = `${URL_PRE}/Reset`;
const RESET_PW_PATH = `${URL_PRE}/reset_password`;
const PROFILE_PATH = `${URL_PRE}/Profile`;
const LOGIN_PATH = `${URL_PRE}/Signin`;
const LOGOUT_PATH = `${URL_PRE}/Signout`;
const ABOUT_PATH = `${URL_PRE}/About`;
const PRIVACY_POLICY_PATH = `${URL_PRE}/PrivacyPolicy`;
const TOS_PATH = `${URL_PRE}/TermsOfService`;
const COMING_SOON_PATH = `${URL_PRE}/ComingSoon`;
const SAVED_REPORTS_PATH = `${URL_PRE}/SavedReports`;
const REPORT_PATH = `${URL_PRE}/Report`;
const REPORT_CREATE_OPTIONS_PATH = `${REPORT_PATH}/create/options`;
const REPORT_CREATE_RESULTS_PATH = `${REPORT_PATH}/create/results`;
const REPORT_RETRIEVE_PATH = `${REPORT_PATH}/retrieve`;
const REPORT_RETRIEVER_PATH = `${REPORT_PATH}/:reportid`;
const REPORT_RETRIEVER_PATH_DEP = `${URL_PRE}/RetrieveReport/:reportid`;
const HMDA_RAW_PATH = `${URL_PRE}/HMDA/Raw`;
const HMDA_AGGREGATE_PATH = `${URL_PRE}/HMDA/Aggregates`;
const COLLECTIONS_PATH = `${URL_PRE}`;
const SFA_HMDA = `${URL_PRE}/SFA/HMDA`;
const SFA_STATE = `${URL_PRE}/SFA/STATE`;
const SFA_TRENDS = `${URL_PRE}/SFA/TRENDS`;
const SFA_HUB = `${URL_PRE}/knowledgehub`;

/* I think it'll be good to get all paths in one object that we can reference
 * like PATHS.SFA.GLOSSARY, since it'll reduce the amount of things exported from here.
 * Idk, seems like it'd be more legible. That being said, one potential issue is that if
 * PATHS.SFA has no key GLOSSARY, referencing it won't throw an error, but will return
 * undefined, which could create problems later. To avoid this, always access this object
 * via the function getPath.
 */
const PATHS = {
  SFA: {
    LIBRARY: `${URL_PRE}/Library`,
    RMBS: `${URL_PRE}/RMBS`,
    GLOSSARY: `${URL_PRE}/Glossary`,
    BIBLIOGRAPHY: `${URL_PRE}/Bibliography`,
    CRT: `${URL_PRE}/CRT`,
    JOURNALHOME: `${URL_PRE}/Journal/*`,
    JOURNALSUBMISSIONS: `${URL_PRE}/Journal/Submissions/*`,
    JOURNALPEERS: `${URL_PRE}/Journal/Peers/*`,
    SEC_CANADA: `${URL_PRE}/SecuritizationCanada`,
    SERVICERPERFORMANCE: `${URL_PRE}/ServicerPerformance`,
    GROSSISSUANCE: `${URL_PRE}/GrossIssuance`,
  },
  REPORT: {
    CREATE: {
      OPTIONS: REPORT_CREATE_OPTIONS_PATH,
      RESULTS: REPORT_CREATE_RESULTS_PATH,
    },
    RETRIEVE: {
      ALL: REPORT_RETRIEVE_PATH,
      ONE: REPORT_RETRIEVER_PATH,
    },
  },
  JOURNAL: {
    DASHBOARD: `${URL_PRE}/Dashboard`,
  },
};
export function getPath(keyString) {
  const keys = keyString.split('.');
  let subobject = PATHS;
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    if (!subobject[key]) {
      throw new Error(`No path found at PATHS.${keyString}.
Available paths are ${JSON.stringify(subobject)}`);
    }
    subobject = subobject[key];
  }
  return subobject;
}

const COLLECTIONS = [
  { url: `${URL_PRE}/misc/ami`, text: 'AMI', collectionName: 'AMI' },
];

const screenWidth = document.documentElement.clientWidth || window.innerWidth;
const MOBILE_WIDTH = 767;
const MOBILE = screenWidth < 767;

export function getSiteVariant() {
  return process.env.REACT_APP_STYLE;
}
const APP_STYLE = getSiteVariant();
const APP_RESOURCE_SUBDIR = ((APP_STYLE && APP_STYLE !== '') ? `${APP_STYLE}/` : '');

// How many days to store saved reports
const REPORT_STORAGE_DURATION = 14;

const PAGE_TITLES = {
  CreateReport: 'Create Report',
  ViewReports: 'View Saved Reports',
  AddDataSource: 'Add a Data Source',
  HMDARaw: 'HMDA: Individual Mortgages',
  HMDAAggregate: 'HMDA: Aggregated Data',
};
export const SITE_VARIANTS = {
  DMM: '',
  SFA: 'sfa-variant',
};

const NAVBAR_STRINGS = {
  ACTIONS_TEXT: 'Actions',
  MISC_DATA_TEXT: 'Geographical Data',
};
const NAVBAR_PAGES = [
  {
    text: NAVBAR_STRINGS.ACTIONS_TEXT,
    children: [
      { url: REPORT_PATH, text: PAGE_TITLES.CreateReport },
      { url: SAVED_REPORTS_PATH, text: PAGE_TITLES.ViewReports },
      { url: DATASOURCEADDER_PATH, text: PAGE_TITLES.AddDataSource },
      {
        text: NAVBAR_STRINGS.MISC_DATA_TEXT,
        children: COLLECTIONS,
      },
    ],
  },
  { url: SOLUTIONS_PATH, text: 'Solutions' },
  { url: ABOUT_PATH, text: 'About' },
];
const ON_SFA = APP_STYLE === SITE_VARIANTS.SFA;
if (ON_SFA) {
  NAVBAR_PAGES.pop();
  NAVBAR_PAGES.pop();
  NAVBAR_PAGES.push({
    text: 'HMDA',
    children: [
      { url: HMDA_RAW_PATH, text: PAGE_TITLES.HMDARaw },
      { url: HMDA_AGGREGATE_PATH, text: PAGE_TITLES.HMDAAggregate },
    ],
  });
}

const HOMEPAGE_REPORT_KEY = (APP_STYLE === SITE_VARIANTS.SFA
  ? 'SFA'
  : 'DMM');
const HOMEPAGE_REPORT_IDS = {
  SFA: 'SFA Home Page',
};
const HOMEPAGE_REPORT = HOMEPAGE_REPORT_IDS[HOMEPAGE_REPORT_KEY];
const TRENDS_REPORTS = [
  'SFA Trends 1',
  'SFA Trends 2',
  'SFA Trends 3',
  'SFA Trends 4',
];

/* https://sashamaps.net/docs/resources/20-colors/ */
const COLORS = [
  '#e6194b',
  '#3cb44b',
  '#ffe119',
  '#4363d8',
  '#f58231',
  '#911eb4',
  '#46f0f0',
  '#f032e6',
  '#bcf60c',
  '#fabebe',
  '#008080',
  '#e6beff',
  '#9a6324',
  '#fffac8',
  '#800000',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#000075',
  '#808080',
  '#ffffff',
  '#000000',
];
const SFA_LINE_GRAPH_COLORS = [
  '#e38706',
  '#110f39',
  '#ee5e4e',
  '#0692e3',
  '#39e30f',
  '#4eeeee',
  '#b18cff',
];
const getColor = (index) => {
  if (APP_STYLE === SITE_VARIANTS.SFA && index < SFA_LINE_GRAPH_COLORS.length) {
    return SFA_LINE_GRAPH_COLORS[index % SFA_LINE_GRAPH_COLORS.length];
  }

  switch (index) {
    case 8: return COLORS[8];
    case 7: return COLORS[7];
    case 6:
      return getComputedStyle(document.documentElement)
        .getPropertyValue('--color-sextary') || COLORS[index];
    case 5:
      return getComputedStyle(document.documentElement)
        .getPropertyValue('--color-quintary') || COLORS[index];
    case 4:
      return getComputedStyle(document.documentElement)
        .getPropertyValue('--color-quartary') || COLORS[index];
    case 3:
      return getComputedStyle(document.documentElement)
        .getPropertyValue('--color-tertiary') || COLORS[index];
    case 2:
      return getComputedStyle(document.documentElement)
        .getPropertyValue('--color-secondary') || COLORS[index];
    case 1:
      return getComputedStyle(document.documentElement)
        .getPropertyValue('--color-primary') || COLORS[index];
    default:
      return getComputedStyle(document.documentElement)
        .getPropertyValue(`--color-${index}`)
        || COLORS[index % 20];
  }
};

/* Credit:
 * https://stackoverflow.com/questions/50940640/how-to-determine-if-jest-is-running-the-code-or-not
 */
export function areWeTestingWithJest() { return process.env.JEST_WORKER_ID !== undefined; }

export {
  NULL_USER, NULL_PATH, HOME_PATH, SIGNUP_PATH, SOLUTIONS_PATH,
  RESET_PATH, RESET_PW_PATH, PROFILE_PATH, CONTACT_PATH, DATASOURCEADDER_PATH,
  ABOUT_PATH, PRIVACY_POLICY_PATH, TOS_PATH, COMING_SOON_PATH, MOBILE_WIDTH, MOBILE,
  LOGIN_PATH, LOGOUT_PATH, NAVBAR_PAGES, REPORT_PATH,
  REPORT_RETRIEVER_PATH, REPORT_RETRIEVER_PATH_DEP, REPORT_STORAGE_DURATION,
  APP_STYLE, APP_RESOURCE_SUBDIR, USER_KEY, HOMEPAGE_REPORT, TRENDS_REPORTS,
  SAVED_REPORTS_PATH,
  SFA_HMDA,
  SFA_STATE,
  SFA_TRENDS,
  SFA_HUB,
  PAGE_TITLES,
  HMDA_RAW_PATH,
  HMDA_AGGREGATE_PATH,
  COLLECTIONS,
  COLLECTIONS_PATH,
  NAVBAR_STRINGS,
  MAP_ENDPOINT,
  COLORS,
  getColor,
  REPORT_CREATE_OPTIONS_PATH,
  REPORT_CREATE_RESULTS_PATH,
  REPORT_RETRIEVE_PATH,
  ON_SFA,
};
export default NONE;
