import React, { createContext, useState, useEffect } from 'react';
import propTypes from 'prop-types';
import axios from 'axios';

import { equivalent, deepCopy } from './core/Utils/objectUtils';

import NONE, {
  MAP_ENDPOINT,
  NULL_USER,
  USER_KEY,
  areWeTestingWithJest,
} from './constants';
import {
  URL_MAP,
  getURL,
  checkAuth,
  registerAuthKey,
  fetchAPIs,
} from './utils/networkUtils';
import { getMenusURL, BASE_URL } from './utils/urlMapUtils';

/*
 * This file contains functions used in getting urls for requests to
 * our backend server.
 */

function getAPISubset() {
  const defaultReturn = process.env.REACT_APP_API_SUBSET;
  return defaultReturn;
}

const API_SUBSET_VAL = getAPISubset();
const API_SUBSET_FLD = 'subset';
const NAVBAR = 'Navbar';
const QUERYFORM = 'QueryForm';
const APIS = 'APIs';
const SEARCH = 'Search';
const CATS = 'Categories';
const LOGIN = 'Login';
const SIGNUP = 'Signup';
const AUTH_KEY_VERIFICATION = 'AuthKey';
const RECIP_LIST = 'ListRecips';
const RECIP_LIST_ADD = 'AddRecip';
const RECIP_LIST_REPLACE = 'ReplaceRecip';
const RPT_DEST_LIST = 'RptDests';
const RPT_DEST_AUTH_LIST = 'RptDestsAuthList';
const RESET_TOK = 'ResetTok';
const DATE_RANGE = 'DateRange';
// FIXME: uncomment the below when the URL path for this is no longer hardcoded
const RESET_PW = 'ResetPW';
const S_TYPES = 'STypes';
const CREATE_RPT = 'CreateReport';
const ADDAPI_FORMS = 'AddAPI-form-list';
const ADDAPI_QUESTIONS = 'AddAPI-form';
const ADDAPI_CREATE = 'CreateAPI';
const MISSING_DATA = 'MissingData';
const REPORT_FORM = 'ReportForm';
const SAVE_REPORT = 'SaveReport';
const DELETE_REPORT = 'DeleteReport';
const RETRIEVE_REPORT = 'RetrieveReport';
const REPORT_LIST = 'ListReports';

const UserContext = createContext();

const USER_ID = 'userID';

function getDefaultUserID() {
  const localVal = localStorage.getItem(USER_ID);
  if (localVal) return localVal;
  return '';
}

function getDefaultUser() {
  const defaultUser = deepCopy(NULL_USER);
  const storedKey = localStorage.getItem(USER_KEY);
  if (!storedKey) { return defaultUser; }
  if (storedKey === 'undefined') { return defaultUser; }
  defaultUser[USER_KEY] = storedKey;
  return defaultUser;
}

export function UserContextProvider({ children }) {
  const [user, setUser] = useState(getDefaultUser());
  const [userID, setUserID] = useState(getDefaultUserID());
  const [receivedURLs, setReceivedURLs] = useState(false);
  const [urls, setUrls] = useState(URL_MAP);
  const [miscDataMenu, setMiscDataMenu] = useState([]);

  localStorage.setItem(USER_ID, userID);
  localStorage.setItem(USER_KEY, user[USER_KEY]);

  useEffect(() => {
    localStorage.setItem(USER_ID, userID);
  }, [userID]);

  useEffect(() => {
    localStorage.setItem(USER_KEY, user[USER_KEY]);
    registerAuthKey(user[USER_KEY]);
  }, [user[USER_KEY]]);

  // Reset all user key information
  function logOut() {
    localStorage.setItem(USER_ID, '');
    localStorage.setItem(USER_KEY, '');
    setUser(deepCopy(NULL_USER));
    setUserID('');
  }

  const loggedIn = () => !equivalent(user, NULL_USER) && userID;

  const ENDPOINT_MAP = `${BASE_URL}/${MAP_ENDPOINT}`;
  useEffect(() => {
    const source = axios.CancelToken.source();
    axios.get(ENDPOINT_MAP, { cancelToken: source.token })
      .then(({ data }) => {
        const additionalUrls = data[Object.keys(data)[0]];
        setUrls(Object.assign(urls, additionalUrls));
        setReceivedURLs(true);
      })
      .catch((error) => {
        // if (ON_STAGING) window.location.reload();
        if (axios.isCancel(error)) {
          return;
        }
        throw error;
      });
    return () => {
      source.cancel('UserContextProvider unmounted');
    };
  }, []);

  useEffect(() => {
    if (!receivedURLs) return;
    const url = `${getMenusURL()}/GeoData`;
    axios.get(url)
      .then(({ data }) => {
        const menu = data['Geographical Data'];
        if (!menu) return;
        setMiscDataMenu(menu);
      })
      .catch(() => {});
    // This can mess up any tests involving UserContext, because
    // it changes how they need to be mocked. Not really sure
    // what the best solution is. - Sam
    if (areWeTestingWithJest()) return;
    fetchAPIs();
  }, [receivedURLs]);

  /*
   * Returns the url schema and domain. No slash at the end.
   */
  function getBaseURL() {
    return BASE_URL;
  }

  function getNavbarURL() {
    return getURL(NAVBAR);
  }

  function getQueryFormURL() {
    return getURL(QUERYFORM);
  }

  function getAPIsURL() {
    let ret = getURL(APIS);
    if (API_SUBSET_VAL) {
      ret += `?${API_SUBSET_FLD}=${API_SUBSET_VAL}`;
    }
    return ret;
  }

  function getAddAPIFormsURL() {
    return getURL(ADDAPI_FORMS);
  }

  function getAddAPIQuestionsURL() {
    return getURL(ADDAPI_QUESTIONS);
  }

  function getAddAPICreateURL() {
    return getURL(ADDAPI_CREATE);
  }

  function getSearchURL(search) {
    let ret = getURL(SEARCH);
    if (search) {
      ret += `?search_for=${search}`;
    }
    return ret;
  }

  function getCatsURL() {
    return getURL(CATS);
  }

  function getLoginURL() {
    return getURL(LOGIN);
  }

  function getSignupURL() {
    return getURL(SIGNUP);
  }

  function getAuthURL() {
    return getURL(AUTH_KEY_VERIFICATION);
  }

  function getRecipListURL() {
    return getURL(RECIP_LIST);
  }

  function getRecipAddURL() {
    return getURL(RECIP_LIST_ADD);
  }

  function getRecipReplaceURL() {
    return getURL(RECIP_LIST_REPLACE);
  }

  function getRptDestsURL() {
    return getURL(RPT_DEST_LIST);
  }

  function getRptDestsAuthListURL() {
    return getURL(RPT_DEST_AUTH_LIST);
  }

  function getResetTokURL(email, server) {
    let ret = getURL(RESET_TOK);
    if (email) ret += `/${email}`;
    if (server) ret += `?server=${server}`;
    return ret;
  }

  function getMissingDataURL() {
    return getURL(MISSING_DATA);
  }

  function getReportFormURL() {
    return getURL(REPORT_FORM);
  }

  function getDateRangeURL() {
    return getURL(DATE_RANGE);
  }

  function getSaveReportURL() {
    return getURL(SAVE_REPORT);
  }

  function getDeleteReportURL() {
    return getURL(DELETE_REPORT);
  }

  function getRetrieveReportURL() {
    return getURL(RETRIEVE_REPORT);
  }

  function getReportListURL() {
    return getURL(REPORT_LIST);
  }

  function getResetPWURL() {
    // FIXME: For some reason, when users take the link to the reset password page,
    // this url is not correctly gotten from the backend server.
    // Hard coded as a short-term fix, but we should get this value from the server.
    // eslint-disable-next-line
    return getURL(RESET_PW);
  }

  function getSTypesURL(apiIds) {
    let ret = getURL(S_TYPES);
    if (apiIds) {
      ret += `?api_ids=${apiIds}`;
    }
    return ret;
  }

  function addURLParam(url, param) {
    if (param.list) {
      const vals = param.val.filter((val) => val !== NONE);
      return `${url}&${param.name}=${vals.join(',')}`;
    }
    // BEGIN kluge
    if (param.name === 'fmt') {
      return `${url}&${param.name}=json`;
    }
    // END kluge
    if (!param.val) {
      return url;
    }
    return `${url}&${param.name}=${param.val}`;
  }

  function getDataURL(form) {
    let ret = getURL(CREATE_RPT);
    if (form) ret += form.reduce(addURLParam, '?');
    return ret;
  }

  function getDataURLReport(form) {
    let ret = `${getURL(CREATE_RPT)}?`;
    if (form) {
      ret += Object.keys(form).map((key) => {
        if (!form[key]) return null;
        return `&${key}=${form[key]}`;
      }).join('');
    }
    return ret;
  }

  function getURLValue(URL, apiIds) {
    if (URL === 'STypes' && apiIds) return getSTypesURL(apiIds);
    return null;
  }

  useEffect(() => {
    if (!user[USER_KEY]) { return; }
    if (!receivedURLs) { return; }
    checkAuth(user, getAuthURL)
      .then(() => {})
      .catch(() => { logOut(); });
  }, [receivedURLs]);

  const value = React.useMemo(() => ({
    user,
    setUser,
    userID,
    setUserID,
    loggedIn,
    getBaseURL,
    getNavbarURL,
    getQueryFormURL,
    getAPIsURL,
    getAddAPIFormsURL,
    getAddAPIQuestionsURL,
    getAddAPICreateURL,
    getCatsURL,
    getLoginURL,
    getSignupURL,
    getAuthURL,
    getRecipListURL,
    getRecipAddURL,
    getRecipReplaceURL,
    getRptDestsURL,
    getRptDestsAuthListURL,
    getResetTokURL,
    getResetPWURL,
    getSearchURL,
    getSTypesURL,
    getSaveReportURL,
    getDeleteReportURL,
    getRetrieveReportURL,
    getMissingDataURL,
    getDataURL,
    getDataURLReport,
    getDateRangeURL,
    getReportFormURL,
    getReportListURL,
    getURLValue,
    miscDataMenu,
    receivedURLs,
    logOut,
  }), [user, receivedURLs, miscDataMenu]);

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
}

UserContextProvider.propTypes = {
  children: propTypes.node.isRequired,
};

export default UserContext;
